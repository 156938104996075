export default function () {
  const resizeBackground = () => {
    const page = document.querySelector("#page");

    if (page.classList.contains("grey")) {
      const ceBody = page.querySelector(".ce-fmc_stage .ce-body");
      if (ceBody) {
        const box = ceBody.getBoundingClientRect();
        page.style.setProperty("--bg-height", `${box.y + box.height + window.scrollY}px`);

        const logos = ceBody.querySelector(".stage-logo-container");
        if (logos) {
          const logoBox = logos.getBoundingClientRect();
          page.style.setProperty("--bg-height", `${logoBox.y + window.scrollY}px`);
        }
      }

      const projectStage = document.querySelector(".project-stage");
      if (projectStage) {
        const content = projectStage.querySelector(".project-stage-content");
        let height = 0;
        if (content) {
          const box = content.getBoundingClientRect();
          height = box.y + box.height;
        }
        const image = projectStage.querySelector(".project-stage-image");
        if (image) {
          height += 240;
        }
        page.style.setProperty("--bg-height", `${height + window.scrollY}px`);
      }
    }
  };

  // Timeout to ensure page is loaded properly
  setTimeout(() => {
    resizeBackground();
  }, 100);

  window.addEventListener("resize", (event) => {
    resizeBackground();
  });
}
