export default function () {
  const prev = document.querySelector("#map-locations-slider-buttons-prev");
  const next = document.querySelector("#map-locations-slider-buttons-next");

  if(!prev) {
    return;
  }

  const locationButtons = document.querySelectorAll(".map-location-trigger");

  const addresses = document.querySelectorAll(".map-location-data .address-data");
  const mapLocationNames = document.querySelector(".map-location-names");

  const mapImage0 = document.querySelector(".map-image-0");
  const mapImage1 = document.querySelector(".map-image-1");
  const mapImage2 = document.querySelector(".map-image-2");
  let mapImage = mapImage0;

  const mapPin = document.querySelector(".map-pin");

  let currentLocationIndex = 0;
  const maxLocationIndex = locationButtons.length - 1;

  next.addEventListener("click", () => {
    currentLocationIndex++;
    showMap();
  });
  prev.addEventListener("click", () => {
    currentLocationIndex--;
    showMap();
  });

  locationButtons.forEach((btn) => {
    btn.addEventListener("click", () => {
      currentLocationIndex = parseInt(btn.dataset.trigger);
      showMap();
    });
  });

  function showMap() {

    // disable left and right buttons
    if (currentLocationIndex === 0) {
      prev.disabled = true;
    } else {
      prev.disabled = false;
    }
    if (currentLocationIndex === maxLocationIndex) {
      next.disabled = true;
    } else {
      next.disabled = false;
    }

    // highlight city name
    locationButtons.forEach((btn) => {
      btn.classList.remove("highlight");
    });
    locationButtons[currentLocationIndex].classList.add("highlight");

    // show correct address
    addresses.forEach((address, idx) => {
      address.classList.remove("highlight");
      if (idx < currentLocationIndex) {
        address.classList.add("left");
      } else {
        address.classList.remove("left");
      }
    });
    addresses[currentLocationIndex].classList.add("highlight");

    // offset city name
    let offset = 0;
    let offsetIdx = 0;
    while (offsetIdx < currentLocationIndex) {
      offset += locationButtons[offsetIdx].getBoundingClientRect().width + 47;
      offsetIdx += 1;
    }
    mapLocationNames.style.setProperty("--offset", `-${offset}px`);

    // calculate pin position
    calcMapPin();
  }

  function calcMapPin() {
    let map = null;
    if (addresses[currentLocationIndex].dataset["second"] == '1') {
      map = mapImage1;
    } else if (addresses[currentLocationIndex].dataset["third"] == '1') {
      map = mapImage2;
    } else {
      map = mapImage0;
    }

    if (map && map != mapImage) {
      mapImage.classList.remove('show');
      mapImage = map;
      mapImage.classList.add('show');
    }

    const mapSize = mapImage.getBoundingClientRect();
    let pinX = addresses[currentLocationIndex].dataset["x"];
    let pinY = addresses[currentLocationIndex].dataset["y"];
    pinX = (pinX / mapImage.naturalWidth) * mapSize.width;
    pinY = (pinY / mapImage.naturalHeight) * mapSize.height;
    mapPin.style.setProperty("--map-pin-left", `${pinX}px`);
    mapPin.style.setProperty("--map-pin-top", `${pinY}px`);
  }

  if (mapImage.complete) {
    calcMapPin();
  } else {
    mapImage.addEventListener('load', calcMapPin)
  }

  window.addEventListener('resize', (event) => {
    calcMapPin()
  });
}
