import { elementInViewport } from "./helpers.js"

function initAnimations() {
    //Fade in from Bottom
    const fadeInBottom = document.querySelectorAll(".ce-body, .team-text, .team-slider-header")

    fadeInBottom.forEach(el => {
        if (elementInViewport(el)) {
            el.style.animation = "fadeInBottom 0.4s ease-out"
        }
    })

    document.addEventListener("scroll", () => {
        fadeInBottom.forEach(el => {
            if (elementInViewport(el) && !el.style.animation) {
                el.style.animation = "fadeInBottom 0.4s ease-out"
            }
        })
    })

    //Quote
    const quoteImages = document.querySelectorAll(".quote-image")
    const quoteContent = document.querySelectorAll(".quote-content")

    fireAnimations(quoteImages, "quoteImageAnimation 0.8s")
    fireAnimations(quoteContent, "quoteContentAnimation 1.2s")

    //Team
    const teamImages = document.querySelectorAll(".team-image")
    const teamRecs = document.querySelectorAll(".team-rectangle")

    fireTeamAnimations(teamImages, "imageAnimationInView 1.2s")
    fireTeamAnimations(teamRecs, "teamRecAnimation 1.2s")

    //Gallery
    const gallery = document.querySelectorAll(".gallery")
    gallery.forEach(el => {
        const image = el.querySelector("img.image-animation--reveal:not(.container-accordeon *)")
        const rec = el.querySelector(".gallery-rectangle")

        if (elementInViewport(image)) {
            image.style.animation = "imageAnimationInView 1s"
            rec.style.animation = "galleryRecAnimation 1s"
        }

        document.addEventListener("scroll", () => {
            if (elementInViewport(image) && !image.style.animation) {
                image.style.animation = "imageAnimationInView 1s"
                rec.style.animation = "galleryRecAnimation 1s"
            }
        })
    })

    //Quotes
    const quotes = document.querySelectorAll(".ce-quotes .gallery")
    quotes.forEach(el => {
        const image = el.querySelector("img")

        if (elementInViewport(image)) {
            image.style.animation = "quoteImageAnimation 1.2s"
        }

        document.addEventListener("scroll", () => {
            if (elementInViewport(image) && !image.style.animation) {
                image.style.animation = "quoteImageAnimation 1.2s"
            }
        })
    })
}

function fireTeamAnimations(elements, animation){
    elements.forEach((el, index) => {
        if (elementInViewport(el)) {
            setTimeout(() => {
                el.style.animation = animation
                el.style.visibility = "visible";
            }, 160 * index)
        }
    })

    document.addEventListener("scroll", () => {
        elements.forEach((el, index) => {
            if (elementInViewport(el) && !el.style.animation) {
                setTimeout(() => {
                    el.style.animation = animation
                    el.style.visibility = "visible";
                }, 160 * index)
            }
        })
    })
}

function fireAnimations(elements, animation){
    elements.forEach(el => {
        if (elementInViewport(el)) {
            el.style.animation = animation
        }
    })

    document.addEventListener("scroll", () => {
        elements.forEach(el => {
            if (elementInViewport(el) && !el.style.animation) {
                el.style.animation = animation
            }
        })
    })
}

export default initAnimations
