function initMenu() {
  const header = document.querySelector('header.header');
  const headerHeight = header.offsetHeight;
  let scrollOffset = 0;
  let headerVisible = true;
  header.style.top = 0;

  window.addEventListener('scroll', (event) => {
    const newScrollOffset = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);

    if (!isNaN(newScrollOffset) && scrollOffset > headerHeight) {
      // below header
      if (newScrollOffset < scrollOffset) {
        // scroll up
        if (!headerVisible) {
          headerVisible = true;
          header.style.top = 0;
        }
      } else {
        // scroll down
        if (headerVisible) {
          headerVisible = false;
          header.style.top = -headerHeight + 'px';
        }
      }
    } else {
      headerVisible = true;
      header.style.top = 0;
    }

    scrollOffset = newScrollOffset;
  })
};

export default initMenu;
