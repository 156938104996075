function initAccordeons() {
  function toggleAriaExpanded(button, off = false) {
    if (button.getAttribute("aria-expanded") === "true" || off) {
      button.setAttribute("aria-expanded", "false");
    } else {
      button.setAttribute("aria-expanded", "true");
    }
  }

  const accordeonParents = document.querySelectorAll(".container-accordeon");
  accordeonParents.forEach((accordeonParent) => {
    const accordeons = accordeonParent.querySelectorAll(".accordeon-item,.accordeon-item--image");

    accordeons.forEach((accordeon, idx) => {
      const btn = accordeon.querySelector(".accordeon-item-btn");
      const ceBody = accordeon.querySelector(".ce-body");
      ceBody.style.setProperty("--max-height", `${ceBody.getBoundingClientRect().height + 30}px`);
      accordeon.classList.add("closed");

      btn.addEventListener("click", function () {
        const toggle = accordeon.classList.contains("closed");
        accordeons.forEach((a) => {
          toggleAriaExpanded(a.querySelector(".accordeon-item-btn"), true);
          a.classList.add("closed");
        });

        if (toggle) {
          toggleAriaExpanded(btn);
          accordeon.classList.remove("closed");
          accordeonParent.classList.remove("bottom");
        }

        if (idx === accordeons.length - 1 && toggle) {
          accordeonParent.classList.add("bottom");
        } else {
          accordeonParent.classList.remove("bottom");
        }
      });
    });
  });
}

export default initAccordeons;
