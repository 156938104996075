export function elementInViewport(el) {
    if (!el) {
        return false;
    }
    const rect = el.getBoundingClientRect();
    return (
        rect.top > 0 && rect.top < innerHeight
    );
}

export function disableScroll(scroll, updateDirection) {
    scroll.disabled = true;
    // Get the current page scroll position
    scroll.y = scroll.y ?? (window.scrollY || document.documentElement.scrollTop);
    scroll.x = scroll.x ?? (window.scrollX || document.documentElement.scrollLeft);
    // if any scroll is attempted, set this to the previous value
    window.onscroll = function (e) {
        if (window.scrollY - scroll.y === 0) {
            return;
        }
        updateDirection(window.scrollY - scroll.y)
        window.scrollTo(scroll.x, scroll.y);
    };

    return scroll
}

export function enableScroll() {
    window.onscroll = function () { };
}