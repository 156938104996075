function initBurger() {
  const burger = document.querySelector(".menu-burger");
  const menuList = document.querySelector(".menu-item-list");
  const body = document.querySelector("body");

  if (typeof burger === "undefined") {
    return;
  }

  burger.addEventListener("click", function () {
    body.classList.toggle("fixed-position");
    menuList.classList.toggle("menu-item-list-active");
    burger.classList.toggle("menu-burger-expanded");

    if (burger.getAttribute("aria-expanded") === "true") {
      burger.setAttribute("aria-expanded", "false");
    } else {
      burger.setAttribute("aria-expanded", "true");
    }
  });
}

export default initBurger;
