import Splide from '@splidejs/splide'

export default function () {
  const projects = document.querySelector(".ce-projects");
  if (!projects) {
    return;
  }

  // const heroSlider = projects.querySelector('.projects-hero');
  const teaserItems = projects.querySelectorAll('.project-teaser-item:not(.project-teaser-interruptor)');
  const loadMoreBtn = projects.querySelector('.projects-load-more');

  if (loadMoreBtn) {
    loadMoreBtn.addEventListener('click', () => {
      showMoreProjects();
    })
  }

  // const selectedTags = {};

  // const tags = projects.querySelectorAll('.projects-filter-tag');
  // tags.forEach(tag => {
  //   selectedTags[tag.dataset.tagId] = false;

  //   tag.addEventListener('click', () => {
  //     tag.classList.toggle('selected');
  //     selectedTags[tag.dataset.tagId] = !selectedTags[tag.dataset.tagId];
  //     updateProjects();
  //   });
  // });

  // const updateProjects = () => {
  //   let anySelected = false;
  //   for (const tagId in selectedTags) {
  //     if (Object.hasOwnProperty.call(selectedTags, tagId)) {
  //       if (selectedTags[tagId]) {
  //         anySelected = true;
  //         break;
  //       }
  //     }
  //   }

  //   if (heroSlider) {
  //     heroSlider.classList.toggle('hidden', anySelected);
  //   }

  //   loadMoreBtn.classList.toggle('hidden', anySelected);

  //   if (!anySelected) {
  //     let i = 0;
  //     teaserItems.forEach(teaser => {
  //       i++;
  //       teaser.classList.toggle('hide', i < ITEMS_PER_PAGE)
  //     })
  //   }

  //   teaserItems.forEach(teaser => {
  //     const teaserTags = JSON.parse(teaser.dataset.tags);
  //     let allFound = true;

  //     for (const selectedTagId in selectedTags) {
  //       const isSelected = selectedTags[selectedTagId];

  //       if (isSelected && !teaserTags.includes(parseInt(selectedTagId))) {
  //         allFound = false;
  //       }
  //     }

  //     teaser.classList.toggle('hide', !allFound);
  //   });
  // }

  const showMoreProjects = () => {
    let i = 0;
    for (let index = 0; index < teaserItems.length; index++) {
      const teaser = teaserItems[index];

      if (teaser.classList.contains('hide')) {
        i++;
        teaser.classList.remove('hide');
      }

      if (i >= ITEMS_PER_PAGE) {
        checkAndHideLoadMoreButton();
        return;
      }
    }

    checkAndHideLoadMoreButton();
  }

  const checkAndHideLoadMoreButton = () => {
    const visibleProjects = document.querySelectorAll(".project-teaser-item:not(.hide):not(.project-teaser-interruptor)");
    if (visibleProjects.length >= teaserItems.length) {
      loadMoreBtn.classList.add('hidden');
    }
  }


  //Mobile
  // const filterButton = document.querySelector(".projects-filter-mobile-btn")
  // const filterItems = document.querySelector(".projects-filter-mobile")

  // if (filterButton) {
  //   filterButton.addEventListener("click", () => {
  //     filterButton.classList.toggle("projects-filter-mobile-btn--opened")
  //     filterItems.classList.toggle("projects-filter-mobile--opened")
  //   })
  // }

  checkAndHideLoadMoreButton();

}
