import Splide from '@splidejs/splide'
import '@splidejs/splide/css';

function initSplide(selector, options) {
    const sliderList = document.querySelectorAll(selector);

    sliderList.forEach(slider => {

        let defaultSplide = new Splide(slider, options).mount()

        const legacyArrowLeft = slider.querySelector(".splide__arrow--prev")
        const legacyArrowRight = slider.querySelector(".splide__arrow--next")
        const arrowsLeft = slider.querySelectorAll(".arrow-left-splide")
        const arrowsRight = slider.querySelectorAll(".arrow-right-splide")
        const arrowsContainer = slider.querySelector(".arrows")

        //Disable arrows when there are not enough slides and enable back on resize
        if (legacyArrowLeft.disabled && legacyArrowRight.disabled) {
            arrowsContainer.style.display = "none";
        } else {
            arrowsContainer.style.display = "";
        }

        arrowsRight.forEach(arrow => {
            arrow.addEventListener('click', e => {
                defaultSplide.go('+1')
            })
        });

        arrowsLeft.forEach(arrow => {
            arrow.addEventListener('click', e => {
                defaultSplide.go('-1')
            })
        });

        const observerLeft = new MutationObserver(() => {
            if (legacyArrowLeft.disabled) {
                arrowsLeft.forEach(arrow => {
                    arrow.disabled = true;
                })
            } else {
                arrowsLeft.forEach(arrow => {
                    arrow.disabled = false;
                })
            }
        });

        const observerRight = new MutationObserver(() => {
            if (legacyArrowRight.disabled) {
                arrowsRight.forEach(arrow => {
                    arrow.disabled = true;
                })
            } else {
                arrowsRight.forEach(arrow => {
                    arrow.disabled = false;
                })
            }
        });

        const config = { attributes: true };
        observerLeft.observe(legacyArrowLeft, config);
        observerRight.observe(legacyArrowRight, config);
    })

}

function initSplides() {
    initSplide(".teamslider-people", {
        perPage: 3,
        gap: "16px",
        pagination: false,
        breakpoints: {
            1439: {
                perPage: 2,
            },
            992: {
                perPage: 1,
            },
        },
    });

    //Fix bug with buttons on resize when the number of slides per page change
    window.addEventListener("resize", () => {
        initSplide(".teamslider-people", {
            perPage: 3,
            gap: "16px",
            pagination: false,
            breakpoints: {
                1439: {
                    perPage: 2,
                },
                992: {
                    perPage: 1,
                },
            },
        });
    })

  initSplide(".ce-quotes", {
        type: "fade",
        pagination: false
    });

    initSplide(".default-slider", {
      pagination: true,
      gap: "1px", // Probably a fix for a rounding error in the slider that only accurs sometimes
    });
}


export default initSplides;
