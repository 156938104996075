export default function () {
  const galleries = document.querySelectorAll(".gallery-slider");
  galleries.forEach((slider) => {

    document.addEventListener("scroll", () => {
      const box = slider.getBoundingClientRect();

      const maxTranslation = slider.dataset.mt ?? (box.left + box.width - window.innerWidth);
      slider.dataset.mt = maxTranslation

      const verticalOffset = 1 - ((box.bottom - box.height) / (window.innerHeight - box.height));
      const translation = Math.min(Math.max(verticalOffset * maxTranslation, 0), maxTranslation);

      slider.style.translate = `-${translation}px`;
    });
  });
}
