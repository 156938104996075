function initLanguage() {
    const languageBtn = document.querySelector(".header-language")
    const menuList = document.querySelector(".header-language-menu")
  
    if (typeof languageBtn === "undefined") {
      return;
    }
  
    languageBtn.addEventListener("click", function () {
        menuList.classList.toggle("visible")
  
        if (languageBtn.getAttribute("aria-expanded") === "true"){
            languageBtn.setAttribute("aria-expanded", "false")
        } else {
            languageBtn.setAttribute("aria-expanded", "true")
        }
    })
  
  }
  
export default initLanguage
  