import { disableScroll, enableScroll } from "../helpers";

export default function () {
  const numbers = document.querySelectorAll(".process-number")
  const slidesContainer = document.querySelector(".process-slides")
  const slides = document.querySelectorAll(".process-slide")
  const dots = document.querySelectorAll(".process-pagination-dot")
  const firstSlider = document.querySelector(".process-slide")
  const firstDot = document.querySelector(".process-pagination-dot")

  const processButtonLeft = document.querySelector(".process-navigation .arrow-left");
  const processButtonRight = document.querySelector(".process-navigation .arrow-right");

  if (!firstSlider) {
    return
  }

  numbers.forEach((number, index) => {
    number.innerHTML = index + 1
  })

  firstSlider.classList.add("highlight");
  firstDot.classList.add("active")

  let currentSlideIndex = 0;

  function updateSlideIndex(amount) {
    currentSlideIndex += amount;
    if(currentSlideIndex <= 0) {
      currentSlideIndex = 0;
      processButtonLeft.disabled = true;
    } else {
      processButtonLeft.disabled = false;
    }
    if(currentSlideIndex >= slides.length - 1) {
      currentSlideIndex = slides.length - 1;
      processButtonRight.disabled = true;
    } else {
      processButtonRight.disabled = false;
    }

    for (let i = 0; i < slides.length; i++) {
      slides[i].classList.remove("highlight");
      dots[i].classList.remove("active");
    }
    slides[currentSlideIndex].classList.add("highlight");
    dots[currentSlideIndex].classList.add("active");
  }


  // Mobile buttons
  processButtonLeft.addEventListener("click", () => {
    updateSlideIndex(-1);
  });
  processButtonRight.addEventListener("click", () => {
    updateSlideIndex(1);
  });


  // Scroll mode

  function nextSlide(forward) {
    if ((currentSlideIndex === 0 && !forward) || (currentSlideIndex === slides.length - 1 && forward)) {
      enableScroll();
      scroll.x = null;
      scroll.y = null;
      scroll.direction = 0;
      scroll.disabled = false;
      scroll.prevDirection = 0;
      scroll.lastEvent = 0;
    } else {
      updateSlideIndex(forward ? 1 : -1);
    }
  }

  const TIME_DELTA = 300;
  let scroll = { x: null, y: null, direction: 0, disabled: false, prevDirection: 0, lastEvent: 0 };

  function handleScroll() {
    const box = slidesContainer.getBoundingClientRect();
    if (!scroll.disabled && Math.abs((window.innerHeight || document.documentElement.clientHeight) - box.bottom - box.top) < 50) {
      scroll = disableScroll(scroll, updateDirection);
    } else if (scroll.disabled) {
      const timestamp = Date.now();
      if (Math.abs(scroll.direction) > Math.abs(scroll.prevDirection) && timestamp > scroll.lastEvent + TIME_DELTA) {
        scroll.lastEvent = timestamp;
        nextSlide(scroll.direction > 0);
      }
    }
  }

  function updateDirection(direction) {
    scroll.prevDirection = scroll.direction
    scroll.direction = direction
  }

  let scrollHandlerActive = false;
  if(window.innerWidth > 992) {
    document.addEventListener("scroll", handleScroll);
    scrollHandlerActive = true;
  }

  window.addEventListener("resize", () => {
    if(window.innerWidth <= 992) {
      document.removeEventListener("scroll", handleScroll);
      scrollHandlerActive = false;
    } else if(!scrollHandlerActive) {
      document.addEventListener("scroll", handleScroll);
      scrollHandlerActive = true;
    }
  })
}
