import initMenu from './js/modules/menu'
import initSplides from './js/modules/splide'
import initAccordeons from './js/modules/accordeon'
import initBurger from './js/modules/menu-burger'
import initLanguage from './js/modules/menu-language'
import initAnimations from './js/animations'
import initScrollButton from './js/modules/scroll-up'
import initLocations from './js/modules/locations'
import initInfoSlider from './js/modules/infoslider'
import initProjects from './js/modules/projects'
import initProcess from './js/modules/process'
import initGallerySlider from './js/modules/gallery-slider'
import initGreyBackground from './js/modules/grey-background'

import "./styles/main.scss"

document.addEventListener('DOMContentLoaded', function () {
  console.log("load all the things");
    initMenu()
    initSplides()
    initAccordeons()
    initBurger()
    initLanguage()
    initAnimations()
    initScrollButton()
    initLocations()
    initInfoSlider()
    initProcess()
    initProjects()
    initGallerySlider()
    initGreyBackground()
})
