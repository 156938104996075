import { disableScroll, enableScroll } from "../helpers";

export default function () {
  const allSliders = document.querySelectorAll(".ce-infoslider");
  if (allSliders.length === 0) {
    return;
  }

  const TIME_DELTA = 300;

  allSliders.forEach((slider) => {
    const titles = slider.querySelectorAll(".infoslide-title");
    const content = slider.querySelectorAll(".infoslide-value");
    const infoButtonLeft = slider.querySelector(".arrow-left");
    const infoButtonRight = slider.querySelector(".arrow-right");

    let currentSlideIndex = 0;
    let scroll = { x: null, y: null, direction: 0, disabled: false, prevDirection: 0, lastEvent: 0 };

    function updateSlideIndex(amount) {
      titles[currentSlideIndex].classList.remove("highlight");
      content[currentSlideIndex].classList.remove("highlight");
      currentSlideIndex = currentSlideIndex + amount;
      for (let i = 0; i < currentSlideIndex; i++) {
        content[i].classList.add("above");
      }
      for (let i = currentSlideIndex + 1; i < titles.length; i++) {
        content[i].classList.add("below");
      }
      titles[currentSlideIndex].classList.add("highlight");
      content[currentSlideIndex].classList.add("highlight");
      content[currentSlideIndex].classList.remove("above");
      content[currentSlideIndex].classList.remove("below");

      // Offset title on mobile
      let offset = 0;
      let offsetIdx = 0;
      while (offsetIdx < currentSlideIndex) {
        offset += titles[offsetIdx].getBoundingClientRect().width + 16;
        offsetIdx += 1;
      }
      slider.querySelector(".infoslide-head").style.setProperty("--offset", `-${offset}px`);

      if (currentSlideIndex <= 0) {
        currentSlideIndex = 0;
        infoButtonLeft.disabled = true;
      } else {
        infoButtonLeft.disabled = false;
      }
      if (currentSlideIndex >= titles.length - 1) {
        currentSlideIndex = titles.length - 1;
        infoButtonRight.disabled = true;
      } else {
        infoButtonRight.disabled = false;
      }
    }

    // Mobile buttons
    infoButtonLeft.addEventListener("click", () => {
      updateSlideIndex(-1);
    });
    infoButtonRight.addEventListener("click", () => {
      updateSlideIndex(1);
    });

    // Scroll mode

    function nextSlide() {
      const forward = scroll.direction > 0;
      if ((currentSlideIndex === 0 && !forward) || (currentSlideIndex === titles.length - 1 && forward)) {
        enableScroll();
        scroll.x = null;
        scroll.y = null;
        scroll.direction = 0;
        scroll.disabled = false;
        scroll.prevDirection = 0;
        scroll.lastEvent = 0;
      } else {
        updateSlideIndex(forward ? 1 : -1);
      }
    }
    // Set Style
    // body block
    let bodyMaxHeight = 0;
    content.forEach((val) => {
      bodyMaxHeight = Math.max(val.getBoundingClientRect().height, bodyMaxHeight);
      if (!val.classList.contains("highlight")) {
        val.classList.add("below");
      }
      val.style.position = "absolute"; // has to be set after height calculation
    });
    const body = slider.querySelector(".infoslide-body").style;
    body.minHeight = `${bodyMaxHeight}px`;
    body.display = "block";

    // Functionality
    function handleScroll() {
      const box = slider.getBoundingClientRect();
      if (!scroll.disabled && Math.abs((window.innerHeight || document.documentElement.clientHeight) - box.bottom - box.top) < 50) {
        scroll = disableScroll(scroll, updateDirection);
      } else if (scroll.disabled) {
        const timestamp = Date.now();
        if (Math.abs(scroll.direction) > Math.abs(scroll.prevDirection) && timestamp > scroll.lastEvent + TIME_DELTA) {
          scroll.lastEvent = timestamp;
          nextSlide();
        }
      }
    }

    function updateDirection(direction) {
      scroll.prevDirection = scroll.direction;
      scroll.direction = direction;
    }

    let scrollHandlerActive = false;
    if (window.innerWidth > 992) {
      document.addEventListener("scroll", handleScroll);
      scrollHandlerActive = true;
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 992) {
        document.removeEventListener("scroll", handleScroll);
        scrollHandlerActive = false;
      } else if (!scrollHandlerActive) {
        document.addEventListener("scroll", handleScroll);
        scrollHandlerActive = true;
      }
    });
  });
}
